import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { RegisterOptions, useForm, useWatch } from 'react-hook-form';
import { ref as firebaseRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import Input from '../../../components/forms/Input';
import { useState } from 'react';
import Select from '../../../components/forms/Select';
import { storage } from '../../../lib/utils/firebase';
import Loader from '../../../components/ui/Loader';
import { Site } from '../../../../../global';
import dayjs from 'dayjs';
import { set } from 'ol/transform';
import Alert from '../../../components/ui/Alert';

type Props = {
  site: Site;
  editable: boolean;
  setStakeholderEvent: any;
  setStakeholderEventIsDirty: any;
  setStakeholderEventIsValid: any;
  stakeholderEvent: {
    eventDone: string;
    image: string;
    modifiedAt: string;
  };
};

const SiteVisitStakeholderEvent = forwardRef((props: Props, ref) => {
  const [file, setFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const [confirmUpload, setConfirmUpload] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const {
    control,
    register,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: { ...props.stakeholderEvent },
    mode: 'all',
  });

  const eventDone = useWatch({
    control,
    name: 'eventDone',
  });
  const image = useWatch({
    control,
    name: 'image',
  });

  const eventDoneOptions: RegisterOptions = {
    required: 'Please select if the event was done or not!',
  };
  const imageOptions: RegisterOptions = {
    required: 'Please upload a picture of the event!',
  };

  useEffect(() => {
    if(isDirty) props.setStakeholderEventIsDirty(isDirty);

    if(image !== '') props.setStakeholderEventIsDirty(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, image]);

  useEffect(() => {
   // Set the product types is valid if all the required fields are filled and make sure if the other option is selected, the other field is filled
   if(
    (eventDone === 'yes' && image && image !== '') ||
    (eventDone === 'no')
   ) {
    props.setStakeholderEventIsValid(true);
   } else {
    props.setStakeholderEventIsValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventDone, image]);

  useImperativeHandle(ref, () => ({
    saveStakeholderEvent() {
      dosSaveStakeholderEvent();
    },
  }));

  const dosSaveStakeholderEvent = () => {
    if(isUploading) return;
    if(!confirmUpload) return;

    handleSubmit(handleSave)();
  };

  const saveFile = async () => {
    setIsUploading(true);
    setConfirmUpload(true);

    await handleSave({ eventDone, image });
  }

  const handleSave = async (data: any) => {
    console.log('check', file, data);

    if (file) {
      const storageRef = firebaseRef(storage, `taeSiteVisits/${props.site.id}/${dayjs().format('YYYYMMDD') + '_' + props.site.id}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
          console.log(`State is ${snapshot.state}`);
        },
        (error) => {
          setFile(null);
          setPreview(null);
          setError('Upload failed: ' + error);
          setIsUploading(false);
          setShowAlert(true);
          console.error('Upload failed', error);
        },
        async () => {
          props.setStakeholderEvent({
            ...data,
            image: `taeSiteVisits/${props.site.id}/${dayjs().format('YYYYMMDD') + '_' + props.site.id}`,
            lastModified: file.lastModified,
          });
          setIsUploading(false);
        }
      );
    } else if (!file) {
      props.setStakeholderEvent(data);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
      setPreview(URL.createObjectURL(selectedFile));
      setConfirmUpload(false); // Reset confirmation on new file selection
    }
  };

  return (
    <>
      <Loader show={isUploading} />
      <Alert
        message={error}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
        title="Error"
      />
      <div className="grid grid-cols-9 col-span-9 ">
        <div className="grid grid-cols-12 col-span-9 content-center ">
          <label
            htmlFor="cementTypeSandcrete"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Did you have a Stakeholder Event?
          </label>
          <Select
            register={register}
            name="eventDone"
            items={[
              {
                key: 'yes',
                label: 'Yes',
                value: 'yes',
              },
              {
                key: 'no',
                label: 'No',
                value: 'no',
              },
            ]}
            placeholder="Did you have a Stakeholder Event?"
            error={errors.eventDone}
            options={eventDoneOptions}
            className="col-span-12"
          />
          {eventDone === 'yes' && !image && (
            <Input  
              register={register}
              name="image"
              type="file"
              error={errors.image}
              options={imageOptions}
              className="col-span-12"
              onChange={handleFileChange}
              accept={'image/*'}
              capture={true}
            />
          )}
          {eventDone === 'yes' && image && (
            <div className="col-span-12">
                <img src={image} alt="Image preview" className="mb-2" />
              </div>
          )}
          {eventDone === 'yes' && preview && (
              <div className="col-span-12">
                <img src={preview} alt="Image preview" className="mb-2" />
                {confirmUpload === false && (
                  <button
                    type="button"
                    onClick={async () => await saveFile()}
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    Confirm and Upload
                  </button>
                )}
              </div>
            )}
        </div>
      </div>
    </>
  );
});

export default SiteVisitStakeholderEvent;
