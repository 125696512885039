import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { RegisterOptions, useForm, useWatch } from 'react-hook-form';
import Select from '../../../components/forms/Select';
import Input from '../../../components/forms/Input';
import { Site } from '../../../../../global';
import Textarea from '../../../components/forms/Textarea';

const VISIT_PURPOSE_SITE = [
  {
    key: 'cement_engagement',
    label: 'Cement engagement',
    value: 'cement_engagement',
  },
  {
    key: 'mortar_engagement',
    label: 'Mortar engagement',
    value: 'mortar_engagement',
  },
  {
    key: 'cement_mortar_engagement',
    label: 'Cement & Mortar engagement',
    value: 'cement_mortar_engagement',
  },
  {
    key: 'follow_up',
    label: 'Follow Up',
    value: 'follow_up',
  },
];

const VISIT_PURPOSE_BLOCKMAKER = [
  {
    key: 'cement_engagement',
    label: 'Cement engagement',
    value: 'cement_engagement',
  },
  {
    key: 'follow_up',
    label: 'Follow Up',
    value: 'follow_up',
  },
];

const ACTIVITY_LEVEL = [
  {
    key: 'foundation',
    label: 'Foundation',
    value: 'foundation',
  },
  {
    key: 'casting',
    label: 'Casting',
    value: 'casting',
  },
  {
    key: 'plastering',
    label: 'Plastering',
    value: 'plastering',
  },
  {
    key: 'block_setting',
    label: 'Block setting',
    value: 'block_setting',
  },
  {
    key: 'tiling_stage',
    label: 'Tiling stage',
    value: 'tiling_stage',
  },
];

const REASON_ACTIVITY_LEVEL = [
  {
    key: 'budget_constraint',
    label: 'Budget constraint',
    value: 'budget_constraint',
  },
  {
    key: 'holiday',
    label: 'Holiday',
    value: 'holiday',
  },
  {
    key: 'high_cost_of_cement',
    label: 'High cost of cement',
    value: 'high_cost_of_cement',
  },
  {
    key: 'high_cost_of_building_materials',
    label: 'High cost of building materials',
    value: 'high_cost_of_building_materials',
  },
  {
    key: 'government_restriction',
    label: 'Government restrictions',
    value: 'government_restriction',
  },
  {
    key: 'shortage_of_labour',
    label: 'Shortage of labour',
    value: 'shortage_of_labour',
  },
  {
    key: 'project_finalized',
    label: 'Project finalized',
    value: 'project_finalized',
  },
  {
    key: 'other',
    label: 'Other',
    value: 'other',
  },
];

const SITE_TYPE = [
  {
    key: 'industrial_project',
    label: 'An Industrial Project - typically a warehouse or factory or multiple company buildings',
    value: 'industrial_project',
  },
  {
    key: 'large_residential',
    label: 'A large Residential Building - typically a duplex or mansion',
    value: 'large_residential',
  },
  {
    key: 'non_building_project',
    label: 'A Non-Building Project - typically a drainage construction, fencing, bridges etc.',
    value: 'non_building_project',
  },
  {
    key: 'commercial_building',
    label: 'A Commercial Building - typically a shopping complex, mall or office complex',
    value: 'commercial_building',
  },
  {
    key: 'residential_ihb',
    label: 'Individual Home Builder (IHB) - typically a small bungalow',
    value: 'residential_ihb',
  },
  {
    key: 'others',
    label: 'Others (specify)',
    value: 'others',
  },
];

const PROJECT_STAGE = [
  {
    key: 'clearing_of_site',
    label: 'Clearing of Site',
    value: 'clearing_of_site',
  },
  {
    key: 'setting_out',
    label: 'Setting out',
    value: 'setting_out',
  },
  {
    key: 'excavation',
    label: 'Excavation',
    value: 'excavation',
  },
  {
    key: 'foundation',
    label: 'Foundation (Substructure)',
    value: 'foundation',
  },
  {
    key: 'block_settings',
    label: 'Block Settings (Superstructure)',
    value: 'block_settings',
  },
  {
    key: 'ground_floor_column_beam_slab',
    label: 'Ground Floor Column, Beam & Slab (Superstructure)',
    value: 'ground_floor_column_beam_slab',
  },
  {
    key: 'first_floor_column_beam_slab',
    label: 'First Floor Column, Beam & Slab (Superstructure)',
    value: 'first_floor_column_beam_slab',
  },
  {
    key: 'second_floor_column_beam_slab',
    label: 'Second Floor Column, Beam & Slab (Superstructure)',
    value: 'second_floor_column_beam_slab',
  },
  {
    key: 'third_floor_column_beam_slab',
    label: 'Third Floor Column, Beam & Slab (Superstructure)',
    value: 'third_floor_column_beam_slab',
  },
  {
    key: 'roofing',
    label: 'Roofing',
    value: 'roofing',
  },
  {
    key: 'plastering',
    label: 'Plastering',
    value: 'plastering',
  },
  {
    key: 'tiling',
    label: 'Tiling',
    value: 'tiling',
  },
  {
    key: 'others',
    label: 'Others (specify)',
    value: 'others',
  },
]

type Props = {
  editable: boolean;
  setStep1: any;
  setStep1IsDirty: any;
  setStep1IsValid: any;
  step1: {
    visitPurpose: string;
    activeSite: string;
    reasonActivityLevel: string;
    stageOfConstruction: string;
    stageOfConstructionOther: string;
    siteAddress: string;
    nameOfRepresentative: string;
    contactNumber: string;
    siteType: string;
    siteTypeOther: string;
    topic: string;
    technicalSupport: string;
  };
  site: Site;
};

const SiteVisitStep1 = forwardRef((props: Props, ref) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: { ...props.step1 },
    mode: 'all',
  });
  const visitPurpose = useWatch({
    control,
    name: 'visitPurpose',
  });
  const activeSite = useWatch({
    control,
    name: 'activeSite',
  });
  const reasonActivityLevel = useWatch({
    control,
    name: 'reasonActivityLevel',
  });
  const stageOfConstruction = useWatch({
    control,
    name: 'stageOfConstruction',
  });
  const stageOfConstructionOther = useWatch({
    control,
    name: 'stageOfConstructionOther',
  });
  const siteAddress = useWatch({
    control,
    name: 'siteAddress',
  });
  const nameOfRepresentative = useWatch({
    control,
    name: 'nameOfRepresentative',
  });
  const contactNumber = useWatch({
    control,
    name: 'contactNumber',
  });
  const siteType = useWatch({
    control,
    name: 'siteType',
  });
  const siteTypeOther = useWatch({
    control,
    name: 'siteTypeOther',
  });
  const topic = useWatch({
    control,
    name: 'topic',
  });
  const technicalSupport = useWatch({
    control,
    name: 'technicalSupport',
  });

  const visitPurposeOptions: RegisterOptions = {
    required: 'Please select a purpose type!',
  };

  const activeSiteOptions: RegisterOptions = {
    required: 'Please select if this customer is still active!',
  };

  const reasonActivityLevelOptions: RegisterOptions = {
    required: 'Please select why this site is inactive!',
  };

  const stageOfConstructionOptions: RegisterOptions = {
    required: 'Please select the stage of construction!',
  };

  const siteAddressOptions: RegisterOptions = {
    required: 'Please enter the address!',
  };

  const nameOfRepresentativeOptions: RegisterOptions = {
    required: 'Please enter the name!',
  };

  const contactNumberOptions: RegisterOptions = {
    required: 'Please enter the contact number!',
    pattern: {
      value: /^\+[0-9]{13}$/,
      message: 'Please enter a valid phone number! (+234 ...)',
    },
  };

  const siteTypeOptions: RegisterOptions = {
    required: 'Please select the site type!',
  };

  const siteTypeOtherOptions: RegisterOptions = {
    required: 'Please specify!',
  };

  const topicOptions: RegisterOptions = {
    required: 'Please enter the topic!',
  };  

  const technicalSupportOptions: RegisterOptions = {
    required: 'Please enter the technical support!',
  };

  const stageOfConstructionOtherOptions: RegisterOptions = {
    required: 'Please specify!',
  };

  useEffect(() => {
    props.setStep1IsDirty(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    if (
      visitPurpose &&
      activeSite &&
      ((activeSite === 'active_2weeks' || activeSite === 'active_month')  ||
        ((activeSite === 'inactive_last3months' || activeSite === 'inactive_more3months') && reasonActivityLevel)) &&
      siteAddress &&
      nameOfRepresentative &&
      contactNumber &&
      (props?.site?.type === 'blockmaker' || (siteType &&
      (siteType !== 'others' || (siteType === 'others' && siteTypeOther)))) &&
      topic &&
      technicalSupport &&
      (props?.site?.type === 'blockmaker' || (stageOfConstruction &&
      (stageOfConstruction !== 'others' || (stageOfConstruction === 'others' && stageOfConstructionOther))))
    ) {
      console.log('valid')
      props.setStep1IsValid(true);
    } else {
      console.log('invalid');
      props.setStep1IsValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitPurpose, activeSite, reasonActivityLevel, stageOfConstruction, siteAddress, nameOfRepresentative, contactNumber, siteType, siteTypeOther, topic, technicalSupport, stageOfConstructionOther]);

  useImperativeHandle(ref, () => ({
    saveStep1() {
      doSaveStep1();
    },
  }));

  const doSaveStep1 = () => {
    handleSubmit(props.setStep1)();
  };

  return (
    <>
      <div className="grid grid-cols-9 col-span-9 ">
        <div className="grid grid-cols-12 col-span-9 content-center ">
          <label
            htmlFor="visitPurpose"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Visit Purpose
          </label>
          <Select
            register={register}
            name="visitPurpose"
            items={ props?.site?.type === 'site' ? VISIT_PURPOSE_SITE : VISIT_PURPOSE_BLOCKMAKER } 
            placeholder="Select visit purpose..."
            error={errors.visitPurpose}
            options={visitPurposeOptions}
            className="col-span-12"
          />
          <label
            htmlFor="activeSite"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Current customer status 
          </label>
          <Select
            register={register}
            name="activeSite"
            items={[
              {
                key: 'active_2weeks',
                label: 'Active within the last 2 weeks (Active)',
                value: 'active_2weeks',
              },
              {
                key: 'active_month',
                label: 'Active within the Month but not the last 2 weeks (Active)',
                value: 'active_month',
              },
              {
                key: 'inactive_last3months',
                label: 'Not Active within the last 3 consecutive months (Inactive)',
                value: 'inactive_last3months',
              },
              {
                key: 'inactive_more3months',
                label: 'Not Active for MORE than 3 consecutive months (Inactive)',
                value: 'inactive_more3months',
              },
            ]}
            placeholder="Is this customer still active?"
            error={errors.activeSite}
            options={activeSiteOptions}
            className="col-span-12"
          />
          {(activeSite === 'inactive_last3months' || activeSite === 'inactive_more3months') && (
            <>
              <label
                htmlFor="reasonActivityLevel"
                className="font-bold col-span-12 mb-2 text-lh-head-black"
              >
                Reason for Site inactive
              </label>
              <Select
                register={register}
                name="reasonActivityLevel"
                items={REASON_ACTIVITY_LEVEL}
                placeholder="Select reason for site inactive..."
                error={errors.reasonActivityLevel}
                options={reasonActivityLevelOptions}
                className="col-span-12"
              />
            </>
          )}
          {props?.site?.type === 'site' && (
            <>
              <label
                htmlFor="activityLevel"
                className="font-bold col-span-12 mb-2 text-lh-head-black"
              >
                What is the stage of the construction?
              </label>
              <Select
                register={register}
              name="stageOfConstruction"
              items={PROJECT_STAGE}
              placeholder="Select the stage of the construction..."
              error={errors.stageOfConstruction}
              options={stageOfConstructionOptions}
              className="col-span-12"
            />
          </>
          )}
          {props?.site?.type === 'site' && stageOfConstruction === 'others' && (
            <>
              <label
                htmlFor="projectStageOther"
                className="font-bold col-span-12 mb-2 text-lh-head-black"
              >
                Specify stage of construction
              </label>
              <Input
                register={register}
                name="stageOfConstructionOther"
                placeholder="Specify..."
                error={errors.stageOfConstructionOther}
                options={stageOfConstructionOtherOptions}
                className="col-span-12"
              />
            </>
           )}
          <label
            htmlFor="siteAddress"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            {props?.site?.type === 'site' ? 'Site Address' : 'Blockmaker Address'} 
          </label>
          <Input
            register={register}
            name="siteAddress"
            placeholder="Enter site address..."
            error={errors.siteAddress}
            options={siteAddressOptions}
            className="col-span-12"
          />
          <label
            htmlFor="nameOfRepresentative"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
           {`Name of  ${props?.site?.type === 'site' ? 'Site Representative' : 'Blockmaker Representative'} during Visit`}
          </label>
          <Input
            register={register}
            name="nameOfRepresentative"
            placeholder="Enter name of..."
            error={errors.nameOfRepresentative}
            options={nameOfRepresentativeOptions}
            className="col-span-12"
          />
          <label
            htmlFor="contactNumber"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            {`Contact Number of ${props?.site?.type === 'site' ? 'Site Representative' : 'Blockmaker Representative'} during Visit`}
          </label>
          <Input
            register={register}
            name="contactNumber"
            placeholder="Enter contact number..."
            error={errors.contactNumber}
            options={contactNumberOptions}
            className="col-span-12"
          />
          {props?.site?.type === 'site' && (
            <>
              <label
                htmlFor="siteType"
                className="font-bold col-span-12 mb-2 text-lh-head-black"
              >
                What is the type of the construction?
              </label>
              <Select
                register={register}
                name="siteType"
                items={SITE_TYPE}
                placeholder="Select site type..."
                error={errors.siteType}
                options={siteTypeOptions}
                className="col-span-12"
              />
          </>
          )}
          {props?.site?.type === 'site' && siteType === 'others' && (
            <>
              <label
                htmlFor="siteTypeOther"
                className="font-bold col-span-12 mb-2 text-lh-head-black"
              >
                Specify the type
              </label>
              <Input
                register={register}
                name="siteTypeOther"
                placeholder="Specify..."
                error={errors.siteTypeOther}
                options={siteTypeOtherOptions}
                className="col-span-12"
              />
            </>
          )}
          <label
            htmlFor="topic"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Major Topic of discussion during Visit
          </label>
          <Textarea
            register={register}
            name="topic"
            error={errors.topic}
            options={topicOptions}
            rows={2}
            className="col-span-12"
          />
          <label
            htmlFor="technicalSupport"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Technical Support Provided
          </label>
          <Textarea
            register={register}
            name="technicalSupport"
            rows={2}
            error={errors.technicalSupport}
            options={technicalSupportOptions}
            className="col-span-12"
          />
        </div>
      </div>
    </>
  );
});

export default SiteVisitStep1;
