import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Input from '../../../components/forms/Input';
import { useForm, useWatch } from 'react-hook-form';

type Props = {
  editable: boolean;
  setPOSM: any;
  setPOSMIsValid: any;
  posm: {
    noPOSMLafarge: boolean;
    chair: boolean;
    table: boolean;
    parasol: boolean;
    bunting: boolean;
    tarpauline: boolean;
    container: boolean;
    abs: boolean;
    aframe: boolean;
    noPOSMComp1: boolean;
    chairComp1: boolean;
    tableComp1: boolean;
    parasolComp1: boolean;
    buntingComp1: boolean;
    tarpaulineComp1: boolean;
    containerComp1: boolean;
    absComp1: boolean;
    aframeComp1: boolean;
    noPOSMComp2: boolean;
    chairComp2: boolean;
    tableComp2: boolean;
    parasolComp2: boolean;
    buntingComp2: boolean;
    tarpaulineComp2: boolean;
    containerComp2: boolean;
    absComp2: boolean;
    aframeComp2: boolean;
    noPOSMComp3: boolean;
    chairComp3: boolean;
    tableComp3: boolean;
    parasolComp3: boolean;
    buntingComp3: boolean;
    tarpaulineComp3: boolean;
    containerComp3: boolean;
    absComp3: boolean;
    aframeComp3: boolean;
  };
};

const VisitStep2 = forwardRef((props: Props, ref) => {
  const [lafargeNoPOSM, setLafargeNoPOSM] = useState(null);
  const [lafargeHasPOSM, setLafargeHasPOSM] = useState(null);
  const [comp1NoPOSM, setComp1NoPOSM] = useState(null);
  const [comp1HasPOSM, setComp1HasPOSM] = useState(null);
  const [comp2NoPOSM, setComp2NoPOSM] = useState(null);
  const [comp2HasPOSM, setComp2HasPOSM] = useState(null);
  const [comp3NoPOSM, setComp3NoPOSM] = useState(null);
  const [comp3HasPOSM, setComp3HasPOSM] = useState(null);

  const { register, handleSubmit, control } = useForm({
    defaultValues: { ...props.posm },
  });

  const noPOSMLafarge = useWatch({
    control,
    name: 'noPOSMLafarge',
  });

  const hasPOSMLafarge = useWatch({
    control,
    name: ['chair', 'table', 'parasol', 'bunting', 'tarpauline', 'container', 'abs', 'aframe']
  });

  const noPOSMComp1 = useWatch({
    control,
    name: 'noPOSMComp1',
  });

  const hasPOSMComp1 = useWatch({
    control,
    name: ['chairComp1', 'tableComp1', 'parasolComp1', 'buntingComp1', 'tarpaulineComp1', 'containerComp1', 'absComp1', 'aframeComp1']
  });

  const noPOSMComp2 = useWatch({
    control,
    name: 'noPOSMComp2',
  });

  const hasPOSMComp2 = useWatch({
    control,
    name: ['chairComp2', 'tableComp2', 'parasolComp2', 'buntingComp2', 'tarpaulineComp2', 'containerComp2', 'absComp2', 'aframeComp2']
  });

  const noPOSMComp3 = useWatch({
    control,
    name: 'noPOSMComp3',
  });

  const hasPOSMComp3 = useWatch({
    control,
    name: ['chairComp3', 'tableComp3', 'parasolComp3', 'buntingComp3', 'tarpaulineComp3', 'containerComp3', 'absComp3', 'aframeComp3']
  });

  useEffect(() => {
    if(noPOSMLafarge == true) {
      setLafargeNoPOSM(true);
    } else {
      setLafargeNoPOSM(false);
    }

    if(hasPOSMLafarge.indexOf(true) !== -1) {
      setLafargeHasPOSM(true);
    } else {
      setLafargeHasPOSM(false);
    }

    if(noPOSMComp1 == true) {
      setComp1NoPOSM(true);
    } else {
      setComp1NoPOSM(false);
    }

    if(hasPOSMComp1.indexOf(true) !== -1) {
      setComp1HasPOSM(true);
    } else {
      setComp1HasPOSM(false);
    }

    if(noPOSMComp2 == true) {
      setComp2NoPOSM(true);
    } else {
      setComp2NoPOSM(false);
    }

    if(hasPOSMComp2.indexOf(true) !== -1) {
      setComp2HasPOSM(true);
    } else {
      setComp2HasPOSM(false);
    }

    if(noPOSMComp3 == true) {
      setComp3NoPOSM(true);
    } else {
      setComp3NoPOSM(false);
    }

    if(hasPOSMComp3.indexOf(true) !== -1) {
      setComp3HasPOSM(true);
    } else {
      setComp3HasPOSM(false);
    }

    if((hasPOSMLafarge.indexOf(true) !== -1 || noPOSMLafarge) && (hasPOSMComp1.indexOf(true) !== -1 || noPOSMComp1) && (hasPOSMComp2.indexOf(true) !== -1 || noPOSMComp2) && (hasPOSMComp3.indexOf(true) !== -1 || noPOSMComp3)) {
      props.setPOSMIsValid(true);
    } else {
      props.setPOSMIsValid(false);
    }


  }, [noPOSMLafarge, hasPOSMLafarge, noPOSMComp1, hasPOSMComp1, noPOSMComp2, hasPOSMComp2, noPOSMComp3, hasPOSMComp3]);

  useImperativeHandle(ref, () => ({
    savePOSM() {
      doSavePOSM();
    },
  }));

  const doSavePOSM = () => {
    handleSubmit(props.setPOSM)();
  };

  return (
    <>
      <div className="grid grid-cols-9 col-span-9 ">
        <div className="grid grid-cols-12 col-span-9 content-center ">
          <p className={'col-span-12 text-left mt-4 font-semibold pl-6'}>
            Capture POSM Lafarge
          </p>
          <p className={'col-span-6 text-left mt-4 mb-2 font-normal'}>
            <p className="text-center">CHAIR</p>
            <Input
              register={register}
              type="checkbox"
              name="chair"
              placeholder="0"
              disabled={props.editable || lafargeNoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mt-4 mb-2 font-normal'}>
            <p className="text-center">TABLE</p>
            <Input
              register={register}
              type="checkbox"
              name="table"
              placeholder="0"
              disabled={props.editable || lafargeNoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">PARASOL</p>
            <Input
              register={register}
              type="checkbox"
              name="parasol"
              placeholder="0"
              disabled={props.editable || lafargeNoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">BUNTING</p>
            <Input
              register={register}
              type="checkbox"
              name="bunting"
              placeholder="0"
              disabled={props.editable || lafargeNoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">TARPAULINE</p>
            <Input
              register={register}
              type="checkbox"
              name="tarpauline"
              placeholder="0"
              disabled={props.editable || lafargeNoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">CONTAINER</p>
            <Input
              register={register}
              type="checkbox"
              name="container"
              placeholder="0"
              disabled={props.editable || lafargeNoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">ABS</p>
            <Input
              register={register}
              type="checkbox"
              name="abs"
              placeholder="0"
              disabled={props.editable || lafargeNoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">A-FRAME</p>
            <Input
              register={register}
              type="checkbox"
              name="aframe"
              placeholder="0"
              disabled={props.editable || lafargeNoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mt-4 mb-2 font-normal'}>
            <p className="text-center">No POSM Lafarge</p>
            <Input
              register={register}
              type="checkbox"
              name="noPOSMLafarge"
              placeholder="0"
              disabled={props.editable || lafargeHasPOSM}
              className={'col-span-3 text-center mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-12 text-left mt-4 font-semibold pl-6'}>
            Capture POSM Comp 1
          </p>
          <p className={'col-span-6 text-left mt-4 mb-2 font-normal'}>
            <p className="text-center">CHAIR</p>
            <Input
              register={register}
              type="checkbox"
              name="chairComp1"
              placeholder="0"
              disabled={props.editable || comp1NoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mt-4 mb-2 font-normal'}>
            <p className="text-center">TABLE</p>
            <Input
              register={register}
              type="checkbox"
              name="tableComp1"
              placeholder="0"
              disabled={props.editable || comp1NoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">PARASOL</p>
            <Input
              register={register}
              type="checkbox"
              name="parasolComp1"
              placeholder="0"
              disabled={props.editable || comp1NoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">BUNTING</p>
            <Input
              register={register}
              type="checkbox"
              name="buntingComp1"
              placeholder="0"
              disabled={props.editable || comp1NoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">TARPAULINE</p>
            <Input
              register={register}
              type="checkbox"
              name="tarpaulineComp1"
              placeholder="0"
              disabled={props.editable || comp1NoPOSM}
              className={'col-span-3  text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">CONTAINER</p>
            <Input
              register={register}
              type="checkbox"
              name="containerComp1"
              placeholder="0"
              disabled={props.editable || comp1NoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">ABS</p>
            <Input
              register={register}
              type="checkbox"
              name="absComp1"
              placeholder="0"
              disabled={props.editable || comp1NoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">A-FRAME</p>
            <Input
              register={register}
              type="checkbox"
              name="aframeComp1"
              placeholder="0"
              disabled={props.editable || comp1NoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mt-4 mb-2 font-normal'}>
            <p className="text-center">No POSM Comp 1</p>
            <Input
              register={register}
              type="checkbox"
              name="noPOSMComp1"
              placeholder="0"
              disabled={props.editable || comp1HasPOSM}
              className={'col-span-3 text-center mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-12 text-left mt-4 font-semibold pl-6'}>
            Capture POSM Comp 2
          </p>
          <p className={'col-span-6 text-left mt-4 mb-2 font-normal'}>
            <p className="text-center">CHAIR</p>
            <Input
              register={register}
              type="checkbox"
              name="chairComp2"
              placeholder="0"
              disabled={props.editable || comp2NoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mt-4 mb-2 font-normal'}>
            <p className="text-center">TABLE</p>
            <Input
              register={register}
              type="checkbox"
              name="tableComp2"
              placeholder="0"
              disabled={props.editable || comp2NoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">PARASOL</p>
            <Input
              register={register}
              type="checkbox"
              name="parasolComp2"
              placeholder="0"
              disabled={props.editable || comp2NoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">BUNTING</p>
            <Input
              register={register}
              type="checkbox"
              name="buntingComp2"
              placeholder="0"
              disabled={props.editable || comp2NoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">TARPAULINE</p>
            <Input
              register={register}
              type="checkbox"
              name="tarpaulineComp2"
              placeholder="0"
              disabled={props.editable || comp2NoPOSM}
              className={'col-span-3  text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">CONTAINER</p>
            <Input
              register={register}
              type="checkbox"
              name="containerComp2"
              placeholder="0"
              disabled={props.editable || comp2NoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">ABS</p>
            <Input
              register={register}
              type="checkbox"
              name="absComp2"
              placeholder="0"
              disabled={props.editable || comp2NoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">A-FRAME</p>
            <Input
              register={register}
              type="checkbox"
              name="aframeComp2"
              placeholder="0"
              disabled={props.editable || comp2NoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mt-4 mb-2 font-normal'}>
            <p className="text-center">No POSM Comp 2</p>
            <Input
              register={register}
              type="checkbox"
              name="noPOSMComp2"
              placeholder="0"
              disabled={props.editable || comp2HasPOSM}
              className={'col-span-3 text-center mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-12 text-left mt-4 font-semibold pl-6'}>
            Capture POSM Comp 3
          </p>
          <p className={'col-span-6 text-left mt-4 mb-2 font-normal'}>
            <p className="text-center">CHAIR</p>
            <Input
              register={register}
              type="checkbox"
              name="chairComp3"
              placeholder="0"
              disabled={props.editable || comp3NoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mt-4 mb-2 font-normal'}>
            <p className="text-center">TABLE</p>
            <Input
              register={register}
              type="checkbox"
              name="tableComp3"
              placeholder="0"
              disabled={props.editable || comp3NoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">PARASOL</p>
            <Input
              register={register}
              type="checkbox"
              name="parasolComp3"
              placeholder="0"
              disabled={props.editable || comp3NoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">BUNTING</p>
            <Input
              register={register}
              type="checkbox"
              name="buntingComp3"
              placeholder="0"
              disabled={props.editable || comp3NoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">TARPAULINE</p>
            <Input
              register={register}
              type="checkbox"
              name="tarpaulineComp3"
              placeholder="0"
              disabled={props.editable || comp3NoPOSM}
              className={'col-span-3  text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">CONTAINER</p>
            <Input
              register={register}
              type="checkbox"
              name="containerComp3"
              placeholder="0"
              disabled={props.editable || comp3NoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">ABS</p>
            <Input
              register={register}
              type="checkbox"
              name="absComp3"
              placeholder="0"
              disabled={props.editable || comp3NoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">A-FRAME</p>
            <Input
              register={register}
              type="checkbox"
              name="aframeComp3"
              placeholder="0"
              disabled={props.editable || comp3NoPOSM}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mt-4 mb-2 font-normal'}>
            <p className="text-center">No POSM Comp3</p>
            <Input
              register={register}
              type="checkbox"
              name="noPOSMComp3"
              placeholder="0"
              disabled={props.editable || comp3HasPOSM}
              className={'col-span-3 text-center mb-2 font-normal '}
            />
          </p>
        </div>
        <p></p>
      </div>
    </>
  );
});
export default VisitStep2;
