import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { RegisterOptions, useForm, useWatch } from 'react-hook-form';
import Input from '../../../components/forms/Input';
import { useState } from 'react';

type Props = {
  editable: boolean;
  setBlockmakersData: any;
  setBlockmakersDirty: any;
  setBlockmakersIsValid: any;
  blockmakersData: {
    lapPurchased: number;
    com1Purchased: number;
    com2Purchased: number;
    com3Purchased: number;
    lapConsumption: number;
    com1Consumption: number;
    com2Consumption: number;
    com3Consumption: number;
    blocksInventory: number;
    blocksSold: number;
  };
};

const VisitStepBlockmakers = forwardRef((props: Props, ref) => {
  const [formValid, setFormValid] = useState(null);
  const {
    control,
    register,
    getValues,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    defaultValues: { ...props.blockmakersData },
    mode: 'all',
  });

  const lapPurchasedAmount = useWatch({
    control,
    name: 'lapPurchased',
  });

  const com1PurchasedInAmount = useWatch({
    control,
    name: 'com1Purchased',
  });

  const com2PurchasedInAmount = useWatch({
    control,
    name: 'com2Purchased',
  });

  const com3PurchasedInAmount = useWatch({
    control,
    name: 'com3Purchased',
  });

  const lapConsumptionAmount = useWatch({
    control,
    name: 'lapConsumption',
  });

  const com1ConsumptionAmount = useWatch({
    control,
    name: 'com1Consumption',
  });

  const com2ConsumptionAmount = useWatch({
    control,
    name: 'com2Consumption',
  });

  const com3ConsumptionAmount = useWatch({
    control,
    name: 'com3Consumption',
  });

  const blocksInventoryAmount = useWatch({
    control,
    name: 'blocksInventory',
  })

  const blocksSoldAmount = useWatch({
    control,
    name: 'blocksSold',
  })

  const amountOptions: RegisterOptions = {
    valueAsNumber: true,
    required: 'Amount is required!',      
    max: {
      value: 10000,
      message: 'Maximum amount is 10\'000',
    },
    min: {
      value: 0,
      message: 'Minimum amount is 0',
    }
  };

  const minAmountOptions: RegisterOptions = {
    valueAsNumber: true,
    required: 'Amount is required!',      
    min: {
      value: 0,
      message: 'Minimum amount is 0',
    }
  };

  useEffect(() => {
    if(
      isValid &&
      lapPurchasedAmount >= 0 &&
      com1PurchasedInAmount >= 0 &&
      com2PurchasedInAmount >= 0 &&
      com3PurchasedInAmount >= 0 &&
      com1ConsumptionAmount >= 0 &&
      com2ConsumptionAmount >= 0 && 
      com3ConsumptionAmount >= 0 &&
      !isNaN(blocksInventoryAmount) && blocksInventoryAmount >= 0 &&
      !isNaN(blocksSoldAmount) && blocksSoldAmount >= 0
    ) {
      props.setBlockmakersDirty(true);
      props.setBlockmakersIsValid(true);
      setFormValid(true);
    } else {
      props.setBlockmakersDirty(false);
      props.setBlockmakersIsValid(false);
      setFormValid(false);
    }

   
  }, [isValid, lapPurchasedAmount, com1PurchasedInAmount, com2PurchasedInAmount, com3PurchasedInAmount, com1ConsumptionAmount, com2ConsumptionAmount, com3ConsumptionAmount, blocksInventoryAmount, blocksSoldAmount]);

  useImperativeHandle(ref, () => ({
    saveBlockmakersData() {
      doSaveBlockmakersData();
    },
  }));

  const doSaveBlockmakersData = () => {
    handleSubmit(props.setBlockmakersData)();
  };

  return (
    <>
      <div className="grid grid-cols-9 col-span-9 ">
        <div className="grid grid-cols-12 col-span-9 content-center ">
          <p className={'col-span-12 text-center mt-4 font-semibold'}>
            Blockmaker Purchases
          </p>
          <p className={'col-span-6 text-left mt-2 mb-4 font-semibold'}>
            Product
          </p>
          <p className={'col-span-6 text-center mt-2 mb-4 font-semibold'}>
            Amount (bags)
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal mt-1'}>
            LAP Purchased
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="lapPurchased"
            placeholder="0"
            error={errors.lapPurchased}
            disabled={props.editable}
            className={'col-span-6  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-6 text-left mb-2 font-normal mt-1'}>
            Com 1 Purchased
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="com1Purchased"
            placeholder="0"
            error={errors.com1Purchased}
            disabled={props.editable}
            className={'col-span-6  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-6 text-left mb-2 font-normal mt-1'}>
            Com 2 Purchased
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="com2Purchased"
            placeholder="0"
            error={errors.com2Purchased}
            disabled={props.editable}
            className={'col-span-6  pr-2 text-right mx-1 mb-2 font-normal'}
          />
           <p className={'col-span-6 text-left mb-2 font-normal mt-1'}>
            Com 3 Purchased
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="com3Purchased"
            placeholder="0"
            error={errors.com3Purchased}
            disabled={props.editable}
            className={'col-span-6  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-12 text-center mt-4 font-semibold'}>
            Blockmaker Consumption
          </p>
          <p className={'col-span-6 text-left mt-2 mb-4 font-semibold'}>
            Product
          </p>
          <p className={'col-span-6 text-center mt-2 mb-4 font-semibold'}>
            Amount (bags)
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal mt-1'}>
            LAP Consumed
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="lapConsumption"
            placeholder="0"
            error={errors.lapConsumption}
            disabled={props.editable}
            className={'col-span-6  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-6 text-left mb-2 font-normal mt-1'}>
            Com 1 Consumed
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="com1Consumption"
            placeholder="0"
            error={errors.com1Consumption}
            disabled={props.editable}
            className={'col-span-6  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-6 text-left mb-2 font-normal mt-1'}>
            Com 2 Consumed
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="com2Consumption"
            placeholder="0"
            error={errors.com2Consumption}
            disabled={props.editable}
            className={'col-span-6  pr-2 text-right mx-1 mb-2 font-normal'}
          />
           <p className={'col-span-6 text-left mb-2 font-normal mt-1'}>
            Com 3 Consumed
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="com3Consumption"
            placeholder="0"
            error={errors.com3Consumption}
            disabled={props.editable}
            className={'col-span-6  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-12 text-center mt-4 font-semibold'}>
            Inventory and Sales
          </p>
          <p className={'col-span-6 text-left mt-2 mb-4 font-semibold'}>
            Product
          </p>
          <p className={'col-span-6 text-center mt-2 mb-4 font-semibold'}>
            Amount (bags)
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal mt-1'}>
            No. of Blocks in Inventory 
          </p>
          <Input
            register={register}
            options={minAmountOptions}
            type="number"
            name="blocksInventory"
            placeholder="0"
            error={errors.blocksInventory}
            disabled={props.editable}
            className={'col-span-6  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-6 text-left mb-2 font-normal mt-1'}>
            No. of Blocks Sold 
          </p>
          <Input
            register={register}
            options={minAmountOptions}
            type="number"
            name="blocksSold"
            placeholder="0"
            error={errors.blocksSold}
            disabled={props.editable}
            className={'col-span-6  pr-2 text-right mx-1 mb-2 font-normal'}
          />
        </div>
        {!formValid && (
          <p
            className={
              'col-span-12 pr-2 text-center text-red-500 mx-1 mb-2 font-normal'
            }
          >
            Please enter values for each field. If it is 0, enter 0.
          </p>
        )}
      </div>
    </>
  );
});

export default VisitStepBlockmakers;
