import { forwardRef, useEffect, useImperativeHandle } from 'react';
import MutliSelect from 'react-select';
import { RegisterOptions, useForm, useWatch } from 'react-hook-form';
import Input from '../../../components/forms/Input';
import { useState } from 'react';
import Select from '../../../components/forms/Select';
import { Site } from '../../../../../global';

const CEMENT_TYPES = [
  {
    key: 'comp2_42n',
    label: 'Comp 2 (42.5N)',
    value: 'comp2_42n',
  },
  {
    key: 'comp1_32r',
    label: 'Comp 1 (32.5R)',
    value: 'comp1_32r',
  },
  {
    key: 'comp1_42n',
    label: 'Comp 1 (42.5N)',
    value: 'comp1_42n',
  },
  {
    key: 'comp1_42r',
    label: 'Comp 1 (42.5R)',
    value: 'comp1_42r',
  },
  {
    key: 'comp3_32n',
    label: 'Comp 3 (32.5N)',
    value: 'comp3_32n',
  },
  {
    key: 'elephant_classic_32n',
    label: 'Elephant Classic (32.5N)',
    value: 'elephant_classic_32n',
  },
  {
    key: 'ecoplanet_32r',
    label: 'ECOPlanet UNICEM  (32.5R)',
    value: 'ecoplanet_32r',
  },
  {
    key: 'ashcem_32n',
    label: 'ASHCEM (32.5N)',
    value: 'ashcem_32n',
  },
  {
    key: 'supaset_42n',
    label: 'Supaset 42.5',
    value: 'supaset_42n',
  },
  {
    key: 'powermax_42n',
    label: 'Powermax 42.5N',
    value: 'powermax_42n',
  },
  {
    key: 'watershield_42n',
    label: 'Watershield 42.5N',
    value: 'watershield_42n',
  },
  {
    key: 'others',
    label: 'Others (specify)',
    value: 'others',
  },
];

const BRANDS = [
  {
    key: 'lap',
    label: 'LAP',
    value: 'lap',
  },
  {
    key: 'comp1',
    label: 'Comp 1',
    value: 'comp1',
  },
  {
    key: 'comp2',
    label: 'Comp 2',
    value: 'comp2',
  },
  {
    key: 'comp3',
    label: 'Comp 3',
    value: 'comp3',
  }
];

const REASON_FOR_PREFERRED_BRAND = [
  {
    key: 'ownersChoice',
    label: 'Owner\'s Choice',
    value: 'ownersChoice',
  },
  {
    key: 'price_of_cement',
    label: 'Price of Cement',
    value: 'price_of_cement',
  },
  {
    key: 'proximity_of_retailers',
    label: 'Proximity of retailers',
    value: 'proximity_of_retailers',
  },
  {
    key: 'recommended_by_artisan',
    label: 'Recommended by Artisan',
    value: 'recommended_by_artisan',
  },
  {
    key: 'sets_faster',
    label: 'Sets faster',
    value: 'sets_faster',
  },
  {
    key: 'others',
    label: 'Others (specify)',
    value: 'others',
  },
];

type Props = {
  editable: boolean;
  setProductTypes: any;
  setProductTypesIsDirty: any;
  setProductTypesIsValid: any;
  productTypes: {
    sandcreteCementTypesSelected: string;
    cementTypeSandcreteOther: string;
    cementTypeUsed: string;
    cementTypeUsedOther: string;
    preferredBrandSelected: string;
    reasonForPreferredBrand: string;
    reasonForPreferredBrandOther: string;
    tileAdhesiveIntent: string;
    nextVisitDate: string;
    feedback: string;
  };
  site: Site;
};

const SiteVisitProductTypes = forwardRef((props: Props, ref) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: { ...props.productTypes },
    mode: 'all',
  });

  const sandcreteCementTypesSelectedData = props.productTypes && props.productTypes.sandcreteCementTypesSelected;
  const cementTypeUsedData = props.productTypes && props.productTypes.cementTypeUsed;

  const preferredBrandSelectedData = props.productTypes &&  props.productTypes.preferredBrandSelected;
  
  const [sandcreteCementTypesSelected, setSandcreteCementTypesSelected] = useState<any>(sandcreteCementTypesSelectedData ?? null);
  const [cementTypeUsed, setCementTypeUsed] = useState<any>(cementTypeUsedData ?? null);

  const [preferredBrandSelected, setPreferredBrandSelected] = useState<any>(preferredBrandSelectedData ?? null);

  const cementTypeSandcreteOther = useWatch({
    control,
    name: 'cementTypeSandcreteOther',
  });

  const cementTypeUsedOther = useWatch({
    control,
    name: 'cementTypeSandcreteOther',
  });

  const reasonForPreferredBrand = useWatch({
    control,
    name: 'reasonForPreferredBrand',
  });
  const reasonForPreferredBrandOther = useWatch({
    control,
    name: 'reasonForPreferredBrandOther',
  });

  const tileAdhesiveIntent = useWatch({
    control,
    name: 'tileAdhesiveIntent',
  });

  const nextVisitDate = useWatch({
    control,
    name: 'nextVisitDate',
  });

  const feedback = useWatch({
    control,
    name: 'feedback',
  });

  const cementTypeSandcreteOptions: RegisterOptions = {
    required: 'Please select a type!',
  };
  const cementTypeSandcreteOtherOptions: RegisterOptions = {
    required: 'Please specify!',
  };

  const cementTypeUsedOptions: RegisterOptions = {
    required: 'Please select a type!',
  }

  const reasonForPreferredBrandOptions: RegisterOptions = {
    required: 'Please select a reason!',
  };
  const reasonWhyNotUsedOtherOptions: RegisterOptions = {
    required: 'Please specify!',
  };

  const tileAdhesiveIntentOptions: RegisterOptions = {
    required: 'Please select an intent!',
  };

  const nextVisitDateOptions: RegisterOptions = {
    required: 'Please select a date!',
  };

  const feedbackOptions: RegisterOptions = {
    required: 'Please provide a feedback!',
  };

  useEffect(() => {
    props.setProductTypesIsDirty(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
   // Set the product types is valid if all the required fields are filled and make sure if the other option is selected, the other field is filled
   if(
    (
      props?.site?.type === 'blockmaker' && (sandcreteCementTypesSelected && !(sandcreteCementTypesSelected.value === 'others') ||
      (sandcreteCementTypesSelected && sandcreteCementTypesSelected.value === 'others') && cementTypeSandcreteOther)
    ) &&
    (
      ((reasonForPreferredBrand && reasonForPreferredBrand !== 'placeholder' && reasonForPreferredBrand !== 'others') ||
      (reasonForPreferredBrand === 'others' && reasonForPreferredBrandOther)) &&
      preferredBrandSelected &&
      nextVisitDate &&
      feedback
      )
   ) {
    props.setProductTypesIsValid(true);
   } else if (
    props?.site?.type === 'site' && 
    ((reasonForPreferredBrand && reasonForPreferredBrand !== 'placeholder' && reasonForPreferredBrand !== 'others') ||
      (reasonForPreferredBrand === 'others' && reasonForPreferredBrandOther)) &&
      preferredBrandSelected &&
      nextVisitDate &&
      feedback &&
      tileAdhesiveIntent && 
      (
        props?.site?.type === 'site' && (cementTypeUsed && !(cementTypeUsed.value === 'others') ||
        (cementTypeUsed && cementTypeUsed.value === 'others') && cementTypeUsedOther)
      )
    ){
    props.setProductTypesIsValid(true);
   } else {
    props.setProductTypesIsValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sandcreteCementTypesSelected, reasonForPreferredBrand, tileAdhesiveIntent, nextVisitDate, feedback, cementTypeSandcreteOther, reasonForPreferredBrandOther, preferredBrandSelected]);

  useImperativeHandle(ref, () => ({
    saveProductTypes() {
      dosSaveProductTypes();
    },
  }));

  const dosSaveProductTypes = () => {
    // get values from form and state
    const values = {
      sandcreteCementTypesSelected: sandcreteCementTypesSelected ?? null,
      cementTypeSandcreteOther: cementTypeSandcreteOther ?? null,
      cementTypeUsed: cementTypeUsed ?? null,
      cementTypeUsedOther: cementTypeUsedOther ?? null,
      preferredBrandSelected: preferredBrandSelected ?? null,
      reasonForPreferredBrand,
      reasonWhyNotUsedOther: reasonForPreferredBrandOther ?? null,
      tileAdhesiveIntent: tileAdhesiveIntent ?? null,
      nextVisitDate,
      feedback,
    };

    // set the product types
    props.setProductTypes(values);
    
    // handleSubmit(props.setProductTypes)();
  };

  return (
    <>
      <div className="grid grid-cols-9 col-span-9 ">
        <div className="grid grid-cols-12 col-span-9 content-center ">
            <label
              htmlFor="cementTypeSandcrete"
              className="font-bold col-span-12 mb-2 text-lh-head-black"
            >
              Preferred brand
            </label>
            <MutliSelect
              options={ BRANDS }
              placeholder={'Select preferred brand...'}
              isMulti={false}
              onChange={setPreferredBrandSelected}
              value={preferredBrandSelected}
              className="col-span-12 mb-2"
            />
          <label
            htmlFor="reasonForPreferredBrand"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
             Reason for preferred brand?
          </label>
          <Select
            register={register}
            name="reasonForPreferredBrand"
            items={REASON_FOR_PREFERRED_BRAND}
            placeholder="Select reason..."
            error={errors.reasonForPreferredBrand}
            options={reasonForPreferredBrandOptions}
            className="col-span-12"
          />
          {props?.site?.type === 'blockmaker' && (
            <>
              <label
                htmlFor="cementTypeSandcrete"
                className="font-bold col-span-12 mb-2 text-lh-head-black"
              >
                Cement type used for Sandcrete Block
              </label>
              <MutliSelect
                options={ CEMENT_TYPES }
                placeholder={'Select cement types...'}
                isMulti={false}
                onChange={setSandcreteCementTypesSelected}
                value={sandcreteCementTypesSelected}
                className="col-span-12 mb-2"
              />
            </>
          )}
          {props?.site?.type === 'blockmaker' && sandcreteCementTypesSelected && sandcreteCementTypesSelected.value === 'others' && (
            <Input
              register={register}
              name="cementTypeSandcreteOther"
              error={errors.cementTypeSandcreteOther}
              options={cementTypeSandcreteOtherOptions}
              placeholder="Please specify..."
              className="col-span-12"
            />
          )}
          {props.site?.type === 'site' && (
            <>
              <label
                htmlFor="cementTypeUsed"
                className="font-bold col-span-12 mb-2 text-lh-head-black"
              >
                Cement type used during visit
              </label>
              <MutliSelect
                options={ CEMENT_TYPES }
                placeholder={'Select cement types...'}
                isMulti={false}
                onChange={setCementTypeUsed}
                value={cementTypeUsed}
                className="col-span-12 mb-2"
              />
            </>
          )}
          {props.site?.type === 'site' && cementTypeUsed && cementTypeUsed.value === 'others' && (
            <Input
              register={register}
              name="cementTypeUsedOther"
              error={errors.cementTypeUsedOther}
              options={cementTypeUsedOptions}
              placeholder="Please specify..."
              className="col-span-12"
            />
          )}
          {reasonForPreferredBrand === 'others' && (
            <Input
              register={register}
              name="reasonForPreferredBrandOther"
              error={errors.reasonForPreferredBrandOther}
              options={reasonWhyNotUsedOtherOptions}
              placeholder="Please specify..."
              className="col-span-12"
            />
          )}
           {props?.site?.type === 'site' && (
            <>
              <label
                htmlFor="tileAdhesiveIntent"
                className="font-bold col-span-12 mb-2 text-lh-head-black"
              >
              What Tile Adhesive do they intend to use or had used?
              </label>
              <Input
                register={register}
                name="tileAdhesiveIntent"
                error={errors.tileAdhesiveIntent}
                options={tileAdhesiveIntentOptions}
                placeholder="Please specify..."
                className="col-span-12"
              />
            </>
           )}
          <label
            htmlFor="nextVisitDate"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            {`When is the next scheduled visit to this ${props.site?.type === 'site' ? 'site' : 'blockermaker'}?`}
          </label>
          <Input
            register={register}
            name="nextVisitDate"
            error={errors.nextVisitDate}
            options={nextVisitDateOptions}
            placeholder="Please specify..."
            className="col-span-12"
            type='date'
          />
          <label
            htmlFor="feedback"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
             {`What was the ${props.site?.type === 'site' ? 'Site Representative' : 'Blockmaker Representative'} feedback about the visit?`}
          </label>
          <Input
            register={register}
            name="feedback"
            error={errors.feedback}
            options={feedbackOptions}
            placeholder="Please specify..."
            className="col-span-12"
          />
        </div>
      </div>
    </>
  );
});

export default SiteVisitProductTypes;
