import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { SiteVisit as SiteVisitType } from '../../../../global';
import { useNavigate } from 'react-router-dom';

type VisitProps = {
  siteName: string;
  siteVisit: SiteVisitType;
  done?: boolean;
  clickable?: boolean;
};

const SiteVisit = (props: VisitProps) => {
  const navigate = useNavigate();

  return (
    <li
      className={
        'flex justify-between mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 cursor-pointer retailer-lh-red bg-white'
      }
      onClick={() => {
        // navigate(`/siteVisits/${props.siteVisit.id}`);
      }}
    >
      <div className="grid grid-cols-12 w-full">
        <p className="col-span-6 text-sm">
          <FontAwesomeIcon icon={faUserCircle} />
          {` ${props.siteName}`}
        </p>
      </div>
      <p className="self-center text-sm w-32 text-center">
        {``}
        <FontAwesomeIcon icon={faAngleRight} />
      </p>
    </li>
  );
};

export default SiteVisit;
