import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { RegisterOptions, useForm, useWatch } from 'react-hook-form';
import Input from '../../../components/forms/Input';
import { useState } from 'react';

type Props = {
  editable: boolean;
  setSellIn: any;
  setSellInDirty: any;
  setSellInIsValid: any;
  sellInOut: {
    lapSellIn: number;
    com1SellIn: number;
    com2SellIn: number;
    com3SellIn: number;
    lapSellOut: number;
    com1SellOut: number;
    com2SellOut: number;
    com3SellOut: number;
  };
};

const VisitStepSellIn = forwardRef((props: Props, ref) => {
  const [formValid, setFormValid] = useState(null);
  const {
    control,
    register,
    getValues,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    defaultValues: { ...props.sellInOut },
    mode: 'all',
  });

  const lapSellInAmount = useWatch({
    control,
    name: 'lapSellIn',
  });

  const com1SellInAmount = useWatch({
    control,
    name: 'com1SellIn',
  });

  const com2SellInAmount = useWatch({
    control,
    name: 'com2SellIn',
  });

  const com3SellInAmount = useWatch({
    control,
    name: 'com3SellIn',
  });

  const lapSellOutAmount = useWatch({
    control,
    name: 'lapSellOut',
  });

  const com1SellOutAmount = useWatch({
    control,
    name: 'com1SellOut',
  });

  const com2SellOutAmount = useWatch({
    control,
    name: 'com2SellOut',
  });

  const com3SellOutAmount = useWatch({
    control,
    name: 'com3SellOut',
  });

  const amountOptions: RegisterOptions = {
    valueAsNumber: true,
    required: 'Amount is required!',
    max: {
      value: 10000,
      message: 'Maximum amount is 10\'000',
    },
  };

  useEffect(() => {
    if(
      isValid &&
      lapSellInAmount >= 0 &&
      com1SellInAmount >= 0 &&
      com2SellInAmount >= 0 &&
      com3SellInAmount >= 0 &&
      lapSellOutAmount >= 0 &&
      com1SellOutAmount >= 0 &&
      com2SellOutAmount >= 0 &&
      com3SellOutAmount >= 0
    ) {
      props.setSellInDirty(true);
      props.setSellInIsValid(true);
      setFormValid(true);
    } else {
      props.setSellInDirty(false);
      props.setSellInIsValid(false);
      setFormValid(false);
    }   
  }, [isValid, lapSellInAmount, com1SellInAmount, com2SellInAmount, com3SellInAmount, lapSellOutAmount, com1SellOutAmount, com2SellOutAmount, com3SellOutAmount]);

  useImperativeHandle(ref, () => ({
    saveSellIn() {
      doSaveSellIn();
    },
  }));

  const doSaveSellIn = () => {
    handleSubmit(props.setSellIn)();
  };

  return (
    <>
      <div className="grid grid-cols-9 col-span-9 ">
        <div className="grid grid-cols-12 col-span-9 content-center ">
          <p className={'col-span-12 text-center mt-4 font-semibold'}>
            Capture Sell In (Retailers Purchases)
          </p>
          <p className={'col-span-6 text-left mt-2 mb-4 font-semibold'}>
            Product
          </p>
          <p className={'col-span-6 text-center mt-2 mb-4 font-semibold'}>
            Sell In (bags)
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal mt-1'}>
            LAP Sell In
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="lapSellIn"
            placeholder="0"
            error={errors.lapSellIn}
            disabled={props.editable}
            className={'col-span-6  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-6 text-left mb-2 font-normal mt-1'}>
            Com 1 Sell In
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="com1SellIn"
            placeholder="0"
            error={errors.com1SellIn}
            disabled={props.editable}
            className={'col-span-6  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-6 text-left mb-2 font-normal mt-1'}>
            Com 2 Sell In
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="com2SellIn"
            placeholder="0"
            error={errors.com2SellIn}
            disabled={props.editable}
            className={'col-span-6  pr-2 text-right mx-1 mb-2 font-normal'}
          />
           <p className={'col-span-6 text-left mb-2 font-normal mt-1'}>
            Com 3 Sell In
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="com3SellIn"
            placeholder="0"
            error={errors.com3SellIn}
            disabled={props.editable}
            className={'col-span-6  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-12 text-center mt-4 font-semibold'}>
            Sell Out (Retailers Sales)
          </p>
          <p className={'col-span-6 text-left mt-2 mb-4 font-semibold'}>
            Product
          </p>
          <p className={'col-span-6 text-center mt-2 mb-4 font-semibold'}>
            Sell Out (bags)
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal mt-1'}>
            LAP Sell Out
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="lapSellOut"
            placeholder="0"
            error={errors.lapSellOut}
            disabled={props.editable}
            className={'col-span-6  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-6 text-left mb-2 font-normal mt-1'}>
            Com 1 Sell Out
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="com1SellOut"
            placeholder="0"
            error={errors.com1SellOut}
            disabled={props.editable}
            className={'col-span-6  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-6 text-left mb-2 font-normal mt-1'}>
            Com 2 Sell Out
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="com2SellOut"
            placeholder="0"
            error={errors.com2SellOut}
            disabled={props.editable}
            className={'col-span-6  pr-2 text-right mx-1 mb-2 font-normal'}
          />
           <p className={'col-span-6 text-left mb-2 font-normal mt-1'}>
            Com 3 Sell Out
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="com3SellOut"
            placeholder="0"
            error={errors.com3SellOut}
            disabled={props.editable}
            className={'col-span-6  pr-2 text-right mx-1 mb-2 font-normal'}
          />
        </div>
        {!formValid && (
          <p
            className={
              'col-span-12 pr-2 text-center text-red-500 mx-1 mb-2 font-normal'
            }
          >
            Please enter values for each field. If it is 0, enter 0.
          </p>
        )}
      </div>
    </>
  );
});

export default VisitStepSellIn;
